import Swiper, { Navigation, EffectFade } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, EffectFade]);

document.addEventListener("DOMContentLoaded", () => {
  (window as any).reviewsSlider = new Swiper(".awards__swiper", {
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      nextEl: ".awards__button--next",
      prevEl: ".awards__button--prev",
    },
    slidesPerView: 1,
    spaceBetween: 16,
  });
});
